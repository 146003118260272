const body = document.body;
const header = document.getElementById('header');

openMenu = () => {
  header.classList.add('open');
  body.classList.add('no-scroll');
}
document.getElementsByClassName('open-menu')[0].addEventListener('click', openMenu);

closeMenu = () => {
  header.classList.remove('open');
  body.classList.remove('no-scroll');
}
document.getElementsByClassName('close-menu')[0].addEventListener('click', closeMenu);
